$(document).ready(function () {
    
    var qsRegex;
    var $grid = $('.grid').isotope({
        layoutMode: 'fitRows',
        fitRows: {
            gutter: 20
        },
        itemSelector: '.grid-item',
        percentPosition: true,
        filter: '.none'
    }); 
    
    
    
    // use value of search field to filter
    var $quicksearch = $('.quicksearch').keyup(debounce(function () {
        qsRegex = new RegExp($quicksearch.val(), 'gi');
        $grid.isotope({
            filter: function () {
                return qsRegex ? $(this).text().match(qsRegex) : true;
            }
        });
    }, 200));
    
    function debounce(fn, threshold) {
        var timeout;
        threshold = threshold || 100;
        return function debounced() {
            clearTimeout(timeout);
            var args = arguments;
            var _this = this;
            
            function delayed() {
                fn.apply(_this, args);
            }
            timeout = setTimeout(delayed, threshold);
        };
    }
    
    $('.filter-btn').on('click', function (e) {
        e.preventDefault();
        $('#swup').slideUp( "slow", function(){});
        $('.is-checked').removeClass('is-checked');
        $(this).addClass('is-checked');
        
        var filterValue = $(this).attr('data-filter');
        location.hash = 'filter=' + encodeURIComponent(filterValue);
        
        $grid.isotope({
            filter: filterValue
        });
        
        if (!$(this).hasClass('btn-produit')) {
            if ($(this).hasClass('btn-nos-produits')) {
                $('svg').removeClass();
                $('svg').addClass('step-produit');
                $('.filter-produits').slideDown();
            } else {
                $('.filter-produits').slideUp();
            }
        }
        
        if (!$(this).hasClass('btn-receipe')) {
            if ($(this).hasClass('btn-receipes')) {
                $('svg').removeClass();
                $('svg').addClass('step-receipe');
                $('.filter-receipes').slideDown();
            } else {
                $('.filter-receipes').slideUp();
            }
        }
        var aid = '#main-nav';
        // $('html,body').animate({scrollTop: $(body).offset().top},'slow');
    });
    
    $('.main-card').on('click', function (e) {
        e.preventDefault();
        var filterValue = $(this).attr('data-filter');
        $grid.isotope({
            filter: filterValue
        });
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        if (!$(this).hasClass('btn-produit')) {
            if ($(this).hasClass('btn-nos-produits') || $(this).hasClass('produit-solo')) {
                $('.filter-produits').find('.is-checked').removeClass('is-checked');
                $(this).addClass('is-checked');
                
                $('.filter-produits').slideDown();
            } else {
                $('.filter-produits').slideUp();
            }
        }
        if (!$(this).hasClass('btn-receipe')) {
            if ($(this).hasClass('block-all-receipes')) {
                $('.filter-receipes').find('.is-checked').removeClass('is-checked');

                $(this).addClass('is-checked');
                $('.filter-receipes').slideDown();
            } else {
                $('.filter-receipes').slideUp();
            }
        }
    });
    
    $('.produit-solo:not(.sub-home), .block-receipe, .block-notre-savoir-faire, .block-le-saviez-vous, .contact-link').click(function () {
        
        $('#swup').slideUp( "slow", function() {
            $('.loader').addClass('shown');
          });
        ($(this).hasClass('breadcrumb') || $(this).hasClass('jeux')) ? '' : $grid.isotope({filter: '.none'});
        console.log('dsdfds')
        // $("html, body").animate({ scrollTop: 0}, 300);
    });
    
    function getHashFilter() {
        var matches = location.hash.match(/filter=([^&]+)/i);
        var hashFilter = matches && matches[1];
        return hashFilter && decodeURIComponent(hashFilter);
    }
    
    let path = window.location.pathname;
    
    let hashFilterEnd;
    
    if(path == "/" || path == "/nl/" ){
        hashFilterEnd = '.block-home';
        $grid.isotope({
            filter: hashFilterEnd
        });   
        $('.block-banner .img-block').slick();
    }else{
        hashFilterEnd = '.none';
        $grid.isotope({
            filter: hashFilterEnd
        });   
    }
    
    function onHashchange() {
        var hashFilter = getHashFilter();
        
        if(path != "/" && path != "/nl/" && hashFilter){
            hashFilterEnd = hashFilter;
        }
        else if (hashFilter) {
            hashFilterEnd = hashFilter;
        }
        else if (path == "/" || path == "/nl/") {
            hashFilterEnd = '.block-home';
            $('.block-banner .img-block').slick('slickNext');
        } else {
            hashFilterEnd = hashFilter
        }
        if(hashFilterEnd == '.block-home'){
            $('.block-banner .img-block').slick('slickNext');
        }
        // isIsotopeInit = true;
        // if (!hashFilter && isIsotopeInit) {
        //     return;
        // }
        // $('.white-container').slideUp()
        
        // filter isotope
        $grid.isotope({
            // itemSelector: '.grid-item',
            filter: hashFilterEnd
        });
        // set selected class on button
        // if (hashFilter) {
        //     $filters.find('.is-checked').removeClass('is-checked');
        //     $filters.find('[data-filter="' + hashFilter + '"]').addClass('is-checked');
        // }
    }
    
    $(window).on('hashchange', onHashchange);

    $('.block-banner .img-block').slick();
    
    
    $('.main-nav--elements a').click(function (e){e.preventDefault()})
    
    $('#print-btn').click(function(e){
        e.preventDefault()
        $('.no-print').hide()
        $("#swup").printThis({importStyle: true, base: true,  copyTagClasses: true, removeInlineSelector: '.similar-receipes'});
    });
    
    getHashFilter();
});